
$(document).ready(function(){
    let $$ = sel => document.querySelector(sel);
    let log = (...args) => console.log(...args);

    $('.home-slider-link').click(function(){
        let slideId = $(this).attr('data-link-to');
        $('.home-slider').attr('data-active-slide', slideId);
        $('.home-slider').addClass('show-slider-contents-xs');
    });

    $('.home-slider-slide-content-heading').click(function(){
        $('.home-slider').removeClass('show-slider-contents-xs');
    });

    $('.slide-customs-calculator-slider-target').each(function(i, sliderEl){
        var customsSlider = noUiSlider.create(sliderEl, {
            start: [20],
            connect: false,
            range: {
                'min': 0,
                '15%': [1, 1],
                '18%': [2, 1],
                '54%': [9, 1],
                '60%': [10, 1],
                '88%': [14, 1],
                'max': 15
            }
        });
        var customsSliderValueElement = $$('.slide-customs-calculator-slider-value');
        sliderEl.noUiSlider.on('update', function (values, handle) {
            var displayValue = Math.round(values[handle]);
            if(displayValue == 0){
                displayValue = '1-მდე';
            }
            if(displayValue == 15){
                displayValue = '14+';
            }
            customsSliderValueElement.innerHTML = displayValue;
        });
    });//end customs slider

    function calculateCustoms() {
        var e = (new Date).getFullYear(),
            t = $("[name=prodyear]").attr('data-value'),
            n = $("[name=enginevolume]").attr('data-value'),
            l = ($$("[name=dollarrate]").value, parseInt($("[name=customs_type]").attr('data-value'))),
            d = parseInt($("[name=tr_days]").attr('data-value')),
            a = $$("[name=right_wheel]").checked,
            c = !o && $("[name=hybrid]").prop("checked"),
            o = !c && $("[name=electric]").prop("checked"),
            m = e - t,
            u = 0;
        if(!t || !n || !l || !d){
            return;
        }
        m < 3 && (u = 1.5), 3 === m && (u = 1.4), 4 === m && (u = 1.2), 5 === m && (u = 1), m > 5 && m < 9 && (u = .8), 9 === m && (u = .9), 10 === m && (u = 1.1), 11 === m && (u = 1.3), 12 === m && (u = 1.5), 13 === m && (u = 1.8), 14 === m && (u = 2.1), m > 14 && (u = 2.4), o && a ? ($("[name=enginevolume]").prop("disabled", !0), n = 2e3) : $("[name=enginevolume]").prop("disabled", !1);
        var r = Math.round(u * n);
        a && !o && (r *= 3), c && t > e - 7 && !a && (r = 40 * r / 100), o && !a ? (r = 0, $("[name=enginevolume]").fadeOut(), n = !1) : $("[name=enginevolume]").fadeIn(), $$("[name=res_customs_duty]").innerHTML = r, $$("[name=res_customs_service_fee]").innerHTML = 150, $$("[name=res_customs_import_duty]").innerHTML = o && !a ? 0 : Math.round(.05 * n + n * m * .0025), total = r + Math.round(.05 * n * (1 + .05 * m)) + 150 + 20 + 30 + l + d, $$("[name=res_customs_total]").innerHTML = total
    }
    $('.home-slider').each(function(){
        $(this).find('.dropdown-item[data-value]').click(function(){
            let $dr = $(this).parents('.dropdown').first();
            let val = $(this).attr('data-value');
            let prefix = $dr.attr('data-prefix') || '';
            let hasSeparateTitle = $dr.find('.dropdown-toggle-text').length > 0;
            let text = $(this).text();
            $dr.attr('data-value',val);
            if(hasSeparateTitle){
                $dr.find('.dropdown-toggle-text').text(prefix + text);
            } else {
                $dr.find('.dropdown-toggle').text(prefix + text);
            }
            //
            $dr.trigger('change');
        });
        $(this).find('.dropdown[name="tc-warehouse"]')
            .find('.dropdown-input')
            .val('')
            .keydown(function(){
                $(this).parents('.dropdown').first().find('.dropdown-menu').addClass('show');
            })
            .keyup(function(){
                let $dr = $(this).parents('.dropdown').first();
                let dr = $dr[0];
                let search = $(this).val().toLowerCase();
                dr.querySelectorAll('.dropdown-item').forEach(item => {
                    // number or undefined
                    let selectedAuctionId = $('[name="tc-auction"]').attr('data-value');
                    let auctionMatches = false;
                    if(!!selectedAuctionId){
                        auctionMatches = item.dataset.auctionId == selectedAuctionId;
                    } else {
                        auctionMatches = true;
                    }
                    if(auctionMatches && item.innerHTML.toLowerCase().indexOf(search) > -1){
                        item.style.display = 'block';
                    } else {
                        item.style.display = 'none';
                    }
                });
                // refresh dropdown menu position
                let html = document.querySelector('html');
                html.scrollTop = html.scrollTop + 1;
                html.scrollTop = html.scrollTop - 1;
            });
        $('.slide-customs-calculator').find('input').change(calculateCustoms);
    });
    $('.slide-customs-calculator').each(function(){
        $(this).find('.dropdown-item[data-value]').click(function(){
            // make sure data-value is set correctly
            setTimeout(function(){ calculateCustoms(); }, 0);
        });
        $(this).find('input').change(calculateCustoms);
    });

    $('input[name=slide-check-document-input]').keyup(function(){
        let val = this.value.toLowerCase();
        $('.slide-check-document-results ul li').each(function(i, el){
            if(el.innerHTML.toLowerCase().indexOf(val) > -1){
                el.style.display = 'block';
            } else {
                el.style.display = 'none';
            }
        });
    });

    $('.slide-check-vin-code .btn-find-by-vin-code').click(function(){
        let vin_code = $$('.slide-check-vin-code [name=vin_code]').value;
        $.ajax({
            type:'POST',
            url:"/ge/car/by-vin-code",
            data:{"vin_code":vin_code,"lang":$('html').attr('lang')},
            success:function(data){
                $('.slide-check-vin-code-results').html(data);
                setTimeout(function(){
                    $('.slide-check-vin-code-results .fancybox').fancybox({
                        padding : 0
                    });
                }, 10);
            }
        });
    });

    function filterWarehouseValues(){
        let srcPortId = $('.dropdown[name=tc-src-port]').attr('data-value');
        let auctionId = $('.dropdown[name=tc-auction]').attr('data-value');
        let filterSel = '';
        if(srcPortId){
            filterSel += '[data-port-id='+srcPortId+']';
        }
        if(auctionId){
            filterSel += '[data-auction-id='+auctionId+']';
        }
        if(filterSel){
            $('.dropdown[name=tc-warehouse] .dropdown-item'+filterSel).show();
            $('.dropdown[name=tc-warehouse] .dropdown-item:not('+filterSel+')').hide();
        }
    }
    $('.slide-transport-calculator').each(function(){
        let $tc_slide = $(this);
        $('.dropdown[name=tc-auction]').on('change', filterWarehouseValues);
        $('.dropdown[name=tc-src-port]').on('change', filterWarehouseValues);
        function getTransportCostForSelectedDestPort(){
            let dest_port_id = $tc_slide.find('[name="tc-dest-port"]').attr('data-value');
            let dest_port_transport_price = dest_port_id ? $tc_slide.find('[name="tc-dest-port"]').find('.dropdown-item[data-value="'+dest_port_id+'"]').attr('data-transport-cost') : 0;
            return parseInt(dest_port_transport_price);
        }
        // some port coombinations (e.g. California to Odessa) may have additional price increases/
        function getPriceIncreaseForPortCombinations(){
            let warehouse_id = $tc_slide.find('[name="tc-warehouse"]').attr('data-value');
            let source_port_id = $tc_slide.find('[name="tc-warehouse"] .dropdown-item[data-value="'+warehouse_id+'"]').attr('data-port-id');
            let dest_port_id = $tc_slide.find('[name="tc-dest-port"]').attr('data-value');
            let combination = window.portPairsIncreasingCost.filter(x => x.source_port_id == source_port_id && x.dest_port_id == dest_port_id);
            if(combination.length){
                //there must/will be only one combination.
                return parseFloat(combination[0].transport_cost);
            } else {
                return 0;
            }
        }
        function getAuctionPriceIncrease(){
            let warehouse_id = $tc_slide.find('[name="tc-warehouse"]').attr('data-value');
            let auction_id = $tc_slide.find('[name="tc-warehouse"] .dropdown-item[data-value="'+warehouse_id+'"]').attr('data-auction-id');
            let auction_price_increase = parseFloat($tc_slide.find('[name="tc-auction"] .dropdown-item[data-value="'+auction_id+'"]').attr('data-transport-price'));
            return auction_price_increase;
        }
        // car type can either multiply the price (e.g. 200%) or add fixed price (e.g. $100).
        function calculateTransportCostForSelectedCarType(price_by_other_parameters){
            let dest_type_id = $tc_slide.find('[name="tc-car-type"]').attr('data-value');
            if(dest_type_id){
                // BEGIN: jeeps only add transport cost in ports of Odessa and Klaipeda
                // [updated: commented the odessa/klaipeda exception. now add jeep cost in all ports]
                // let jeep_cartype_id = 2;
                // let ids_of_ports_only_in_which_jeeps_cost_more = [10,12];
                // let dest_port_id = $tc_slide.find('[name="tc-dest-port"]').attr('data-value');
                // let is_any_port_selected = dest_port_id > 0;
                // let is_jeep_selected = dest_type_id == jeep_cartype_id;
                // let is_port_other_than_odessa_klaipeda = ids_of_ports_only_in_which_jeeps_cost_more.indexOf(parseInt(dest_port_id)) == -1;
                // if(is_jeep_selected){
                //     if(!is_any_port_selected){
                //         //by default, don't add jeep's cost when no port is selected. only add it to odessa and klaipeda
                //         return price_by_other_parameters;
                //     }
                //     if(is_any_port_selected && is_port_other_than_odessa_klaipeda){
                //         //this is a jeep but it's not going to neither Odessa or Klaipeda, so we won't add the jeep's additional transport cost.
                //         return price_by_other_parameters;
                //     }
                // }
                // END jeeps/odessa/klaipeda logic
                let $selected_type = $tc_slide.find('[name="tc-car-type"]').find('.dropdown-item[data-value="'+dest_type_id+'"]');
                let dest_type_transport_price = parseInt($selected_type.attr('data-transport-cost'));
                let dest_type_transport_multiply = parseFloat($selected_type.attr('data-transport-cost-multiply-by'));
                if(dest_type_transport_multiply > 0){
                    return price_by_other_parameters * dest_type_transport_multiply;
                } else {
                    return price_by_other_parameters + dest_type_transport_price;
                }
            } else {
                return price_by_other_parameters;
            }
        }
        $('.dropdown[name="tc-warehouse"],.dropdown[name="tc-dest-port"],.dropdown[name="tc-car-type"]').on('change', function(){
            let $tc_warehouse = $('.dropdown[name="tc-warehouse"]');
            let warehouseId = $tc_warehouse.attr('data-value');
            let $tc_dest_port = $('.dropdown[name="tc-dest-port"]');
            let destPortId = $tc_dest_port.attr('data-value');
            if(!warehouseId){
                return;
            }
            if(!destPortId){
                return;
            }
            let public_transport_price = parseInt($tc_warehouse.find('.dropdown-item[data-value='+warehouseId+']').attr('data-transport-price'));
            let dest_port_transport_price = getTransportCostForSelectedDestPort();
            let port_combination_increase = getPriceIncreaseForPortCombinations();
            let auction_price_increase = getAuctionPriceIncrease();
            let final_price = calculateTransportCostForSelectedCarType(public_transport_price + dest_port_transport_price + auction_price_increase + port_combination_increase);
            $('.slide-transport-calculator .home-slider-total-value').text(final_price+'$');
        });
    });

});
