require('./bootstrap');// Laravel bootstrapping script.

require('./common');

require('./jquery.youtubebackground');

require('./home-slider');

function isMobile(){
    return $(window).width() < 768;
}

$(document).ready(function(){
    let $$ = sel => document.querySelector(sel);
    let log = (...args) => console.log(...args);

    let scrollTo = sel => {
        let el = $$(sel);
        if(!el) return;
        /*el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            });*/
        $([document.documentElement, document.body]).animate({
            scrollTop: $(el).offset().top - 200
        }, 1000);
    };

    if($('main.home').length){
        $('.home-slider-video-bg-target').YTPlayer({
            fitToBackground: true,
            videoId: $(".home-slider-video-bg").attr("data-video-id"),
            playerVars: {
                modestbranding: 1,
                autoplay: 1,
                loop: 1,
                controls: 0,
                disablekb: 1,
                fs: 0,
                playsinline: 1,
                showinfo: 0,//deprecated
                rel: 0,//deprecated
                branding: 0,
                autohide: 0,
                start: 0
            }
        });
        $('.navbar-brand').click(function(e){
            scrollTo('#home');
            e.preventDefault();
        });
        $('.navbar-nav a').click(function(e){
            let href = $(this).attr('href').toString();
            let id = href.substr(href.lastIndexOf('/')+1);
            // if on mobile, first close the menu
            if(isMobile()){
                $('.navbar-toggler').click();
            }
            //
            //log(id);
            $('.navbar-nav > li').removeClass('active');
            $(this).parent().addClass('active');
            scrollTo('#'+id);
            //
            e.preventDefault();
        });
    }

    $('.navbar-toggler').click(function(){
        $('body').toggleClass('is-navbar-open');
    });

});
